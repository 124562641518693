import { MessageInstance } from 'antd/es/message/interface';
import { NavigateFunction } from 'react-router-dom';

import { INotificationsStore, NotificationsStore } from '@/entities/message';
import { UserStore } from '@/entities/user';
import { RouterStore } from '@/shared/model';

import { IRootStore } from './types';

type RootStoreParams = {
  messageApi: MessageInstance;
  navigate: NavigateFunction;
};

export class RootStore implements IRootStore {
  readonly notificationsStore: INotificationsStore;
  readonly routerStore: RouterStore;
  readonly userStore: UserStore = new UserStore();

  constructor({ messageApi, navigate }: RootStoreParams) {
    this.notificationsStore = new NotificationsStore(messageApi);
    this.routerStore = new RouterStore({ navigate });
  }
}
