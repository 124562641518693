import { Menu } from 'antd';
import cn from 'classnames';
import * as React from 'react';
import { useLocation } from 'react-router';

import { useRootStore } from '@/app/store';
import { Logo } from '@/shared/ui';
import { getRootPathname } from '@/shared/utils';

import { getMenuItems, menuUrlsMap } from '../../config';
import { SideMenuButton } from '../SideMenuButton';

import s from './SideMenu.module.scss';

type Props = {
  className?: string;
  isMenuOpened: boolean;
  toggleMenuOpen?: VoidFunction;
  closeMenu?: VoidFunction;
};

const SideMenu: React.FC<Props> = ({ className, isMenuOpened, toggleMenuOpen, closeMenu }) => {
  const { userStore, routerStore } = useRootStore();
  const location = useLocation();

  const [selectedKey, setSelectedKey] = React.useState<string>(getRootPathname(location));

  React.useEffect(() => {
    setSelectedKey(getRootPathname(location));
  }, [location.pathname]);

  const handleMenuItemClick = React.useCallback(
    ({ key }: { key: string }) => {
      if (key === menuUrlsMap.logout.key) {
        userStore.logout();
      }

      routerStore.navigate(menuUrlsMap[key].url);
      setSelectedKey(key);
      closeMenu?.();
    },
    [closeMenu],
  );

  const items = React.useMemo(() => getMenuItems(selectedKey, handleMenuItemClick), [selectedKey, handleMenuItemClick]);

  return (
    <div className={cn(s['side-menu'], !isMenuOpened && s['side-menu_closed'], className)}>
      <Logo className={cn(s['side-menu__logo'], !isMenuOpened && s['side-menu__logo_closed'])} size={16} />
      <SideMenuButton onClick={toggleMenuOpen ?? closeMenu} className={s['side-menu__button']} />
      <Menu
        className={s['side-menu__menu']}
        defaultSelectedKeys={[selectedKey]}
        selectedKeys={[selectedKey]}
        mode="inline"
        theme="light"
        inlineCollapsed={!isMenuOpened}
        items={items}
      />
    </div>
  );
};

export default React.memo(SideMenu);
