import { BaseResponse } from '@kts-front/types';
import { action, computed, makeObservable, runInAction } from 'mobx';

import { UserServer, UserType } from '@/entities/user';
import { AgentModel } from '@/entities/user/model/AgentModel';
import { CustomerModel } from '@/entities/user/model/CustomerModel';
import { SupplierModel } from '@/entities/user/model/SupplierModel';
import { apiStore, apiUrls } from '@/shared/api';
import { LoadingStageModel, ValueModel } from '@/shared/model';
import { ownLocalStorage, ownSessionStorage } from '@/shared/model/storage';
import { ApiErrorCode, ApiErrorData } from '@/shared/types/meta';
import { Nullable } from '@/shared/types/values';

import { ManagerModel } from '../ManagerModel';
import { UserModelType } from '../types';

import { IUserStore } from './types';

export class UserStore implements IUserStore {
  private _userModel = new ValueModel<Nullable<UserModelType>>(null);

  private readonly currentUserRequest = apiStore.createRequest({
    url: apiUrls.user.current,
  });

  readonly currentUserLoadingStage = new LoadingStageModel();

  constructor() {
    makeObservable(this, {
      authorized: computed,
      userModel: computed,
      isManagerRole: computed,

      logout: action.bound,
    });
  }

  get userModel(): Nullable<UserModelType> {
    return this._userModel.value;
  }

  get isManagerRole(): boolean {
    return this.userModel?.type === UserType.manager;
  }

  get authorized(): boolean {
    return this.userModel !== null;
  }

  getCurrentUser = async (): Promise<BaseResponse> => {
    if (this.currentUserLoadingStage.isLoading) {
      return { isError: true };
    }

    this.currentUserLoadingStage.loading();

    const response = await this.currentUserRequest.call<{ user: UserServer }>();

    if (response.isError) {
      this.currentUserLoadingStage.error();

      const data: Nullable<ApiErrorData> = response.data?.data ?? null;

      if (data && data.code === ApiErrorCode.tokenNotValid) {
        ownLocalStorage.clear();
        ownSessionStorage.clear();
      }

      return { isError: true };
    }

    runInAction(() => {
      this.setUserFromJson(response.data.user);
      this.currentUserLoadingStage.success();
    });

    return { isError: false };
  };

  setUser = (value: Nullable<UserModelType>) => {
    this._userModel.change(value);
  };

  setUserFromJson = (raw: UserServer): void => {
    let userModel: UserModelType;

    switch (raw.type) {
      case UserType.customer: {
        userModel = CustomerModel.fromJson(raw);

        break;
      }

      case UserType.agent: {
        userModel = AgentModel.fromJson(raw);

        break;
      }

      case UserType.supplier: {
        userModel = SupplierModel.fromJson(raw);

        break;
      }

      case UserType.manager: {
        userModel = ManagerModel.fromJson(raw);

        break;
      }
    }

    this.setUser(userModel);
  };

  logout(): void {
    ownLocalStorage.clear();
    ownSessionStorage.clear();
    this.setUser(null);
  }
}
