import * as React from 'react';
import { Outlet } from 'react-router-dom';

import { useNetworkStatus } from '@/shared/hooks/useNetworkStatus';
import { WidthProvider } from '@/shared/hooks/useWidth';
import { Loader, OfflineStatus } from '@/shared/ui';
import { SideMenuPageLayout } from '@/widgets/menu';

import { RootStoreContextProvider } from './store';

export const App = (): React.ReactElement => {
  const { isOnline } = useNetworkStatus();

  return isOnline ? (
    <RootStoreContextProvider>
      <WidthProvider>
        <SideMenuPageLayout>
          <React.Suspense fallback={<Loader />}>
            <Outlet />
          </React.Suspense>
        </SideMenuPageLayout>
      </WidthProvider>
    </RootStoreContextProvider>
  ) : (
    <OfflineStatus />
  );
};
