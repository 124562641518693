import { ID } from '../types/meta';

export const apiPrefix = '/api';

const getClientPrefix = (endpoint: string): string => `/client/${endpoint}`;
const getUserPrefix = (endpoint: string): string => `/user/${endpoint}`;
const getAgentPrefix = (endpoint: string): string => `/agent/${endpoint}`;
const getTradePrefix = (endpoint: string): string => `/trade/${endpoint}`;
const getDocumentPrefix = (endpoint: string): string => `/document/${endpoint}`;
const getDictionaryPrefix = (endpoint: string): string => `/handbook/${endpoint}`;

export const apiUrls = {
  user: {
    current: getUserPrefix('current'),
    add: getUserPrefix('add'),
    changePassword: getUserPrefix('change_password'),
    login: getUserPrefix('login'),
  },
  client: {
    list: getClientPrefix('list'),
  },
  agent: {
    list: getAgentPrefix('list'),
  },
  trade: {
    list: getTradePrefix('list'),
    download: getTradePrefix('list/download'),
    create: getTradePrefix('create'),
    details: (id: ID) => getTradePrefix(String(id)),
    edit: (id: ID) => getTradePrefix(`${id}/edit`),
    action: (id: ID) => getTradePrefix(`${id}/action`),
    history: (id: ID) => getTradePrefix(`${id}/logs`),
    upload: (id: ID) => getTradePrefix(`${id}/upload`),
  },
  document: {
    list: getDocumentPrefix('list'),
    download: getDocumentPrefix('list/download'),
    filters: getDocumentPrefix('filters'),
    original: (id: ID) => getDocumentPrefix(`${id}/original`),
  },
  dictionaries: {
    legalCompanyList: getDictionaryPrefix('legal_company'),
    logisticCompanyList: getDictionaryPrefix('logistic_company'),
    currencyList: getDictionaryPrefix('currency'),
    productTypeList: getDictionaryPrefix('product_type'),
    metalList: getDictionaryPrefix('metal'),
  },
};
