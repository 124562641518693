import * as React from 'react';

import { IconProps } from '../types';

const DeliveryIcon: React.FC<IconProps> = ({ size = 16, ...props }) => {
  return (
    <svg {...props} width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.37631 5.81984L6.71964 7.06184C7.04297 8.23184 7.20431 8.81717 7.67964 9.08317C8.15497 9.34984 8.75831 9.1925 9.96497 8.87917L11.245 8.54584C12.4516 8.2325 13.055 8.07584 13.3296 7.61517C13.6043 7.15384 13.443 6.5685 13.119 5.3985L12.7763 4.15717C12.453 2.9865 12.291 2.40117 11.8163 2.13517C11.3403 1.8685 10.737 2.02584 9.53031 2.33984L8.25031 2.67184C7.04364 2.98517 6.44031 3.1425 6.16631 3.60384C5.89164 4.0645 6.05297 4.64984 6.37631 5.81984Z"
        fill="currentColor"
        fillOpacity="0.88"
      />
      <path
        d="M1.51813 3.49818C1.53569 3.43486 1.56556 3.37563 1.60603 3.32387C1.64651 3.2721 1.69679 3.22882 1.75399 3.1965C1.8112 3.16418 1.87422 3.14346 1.93945 3.13551C2.00468 3.12756 2.07083 3.13255 2.13413 3.15018L3.26946 3.46485C3.57035 3.54657 3.84494 3.70485 4.06646 3.92426C4.28798 4.14368 4.44887 4.41675 4.53346 4.71685L5.96746 9.90751L6.07279 10.2722C6.49796 10.4289 6.85625 10.7271 7.08746 11.1168L7.29413 11.0528L13.2075 9.51618C13.271 9.49963 13.3372 9.49577 13.4023 9.5048C13.4673 9.51384 13.53 9.5356 13.5866 9.56884C13.6432 9.60209 13.6928 9.64616 13.7324 9.69855C13.772 9.75094 13.8009 9.81062 13.8175 9.87418C13.834 9.93774 13.8379 10.0039 13.8288 10.069C13.8198 10.134 13.798 10.1967 13.7648 10.2533C13.7316 10.31 13.6875 10.3595 13.6351 10.3991C13.5827 10.4387 13.523 10.4676 13.4595 10.4842L7.56813 12.0148L7.34812 12.0828C7.34413 12.9295 6.75946 13.7042 5.87479 13.9335C4.81479 14.2095 3.72479 13.5988 3.44079 12.5708C3.15679 11.5422 3.78613 10.4848 4.84613 10.2095C4.89836 10.1961 4.95106 10.1845 5.00413 10.1748L3.56946 4.98285C3.53084 4.84946 3.45835 4.72833 3.35905 4.63125C3.25976 4.53417 3.13702 4.46444 3.00279 4.42885L1.86679 4.11351C1.80349 4.09602 1.74425 4.06622 1.69247 4.02582C1.64068 3.98542 1.59736 3.93522 1.56498 3.87808C1.5326 3.82094 1.5118 3.75798 1.50376 3.69279C1.49572 3.62761 1.5006 3.56148 1.51813 3.49818Z"
        fill="currentColor"
        fillOpacity="0.88"
      />
    </svg>
  );
};

export default DeliveryIcon;
