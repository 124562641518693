import { CheckCircleFilled } from '@ant-design/icons';
import { Typography } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { CustomModal } from '@/shared/ui';

import s from './SuccessModal.module.scss';

type SuccessModalProps = {
  className?: string;
  title: string;
  isOpen: boolean;
  onClose: VoidFunction;
};

const SuccessModal: React.FC<SuccessModalProps> = ({ className, title, isOpen, onClose }) => {
  return (
    <CustomModal className={cn(s.success_modal, className)} open={isOpen} onCancel={onClose} width={600}>
      <CheckCircleFilled className={s.success_modal__icon} />
      <Typography.Title level={5}>{title}</Typography.Title>
    </CustomModal>
  );
};

export default observer(SuccessModal);
