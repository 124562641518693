import * as React from 'react';

const DEVICES = {
  phone: {
    minWidth: 0,
    maxWidth: 580,
  },
  tablet: {
    minWidth: 581,
    maxWidth: 1024,
  },
  isPhone: (width: number): boolean => width <= DEVICES.phone.maxWidth && width >= DEVICES.phone.minWidth,
  isTablet: (width: number): boolean => width <= DEVICES.tablet.maxWidth && width >= DEVICES.tablet.minWidth,
  isDesktop: (width: number): boolean => width > DEVICES.tablet.maxWidth,
};

const useSubscribeToWidth = (): number => {
  const [width, setWidth] = React.useState(typeof window === 'undefined' ? Infinity : window.innerWidth);
  const unmounted = React.useRef(false);

  React.useEffect(() => {
    const handleResize = (): void => {
      if (unmounted.current) {
        return;
      }

      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return (): void => {
      unmounted.current = true;
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return width;
};

type WidthContextParams = {
  width: number | null;
};

const initialValue: WidthContextParams = {
  width: null,
};

const WidthContext = React.createContext<WidthContextParams>(initialValue);

export const WidthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const width = useSubscribeToWidth();

  const contextValue = React.useMemo<WidthContextParams>(() => ({ width }), [width]);

  return <WidthContext.Provider value={contextValue}>{children}</WidthContext.Provider>;
};

export const useWidth = (): {
  width: number;
  isPhone: boolean;
  isTablet: boolean;
  isDesktop: boolean;
} => {
  const { width: contextWidth } = React.useContext(WidthContext);

  if (!contextWidth) {
    throw new Error('contextWidth was not provided');
  }

  const isPhone = DEVICES.isPhone(contextWidth);
  const isTablet = DEVICES.isTablet(contextWidth);
  const isDesktop = DEVICES.isDesktop(contextWidth);

  return { width: contextWidth, isPhone, isTablet, isDesktop };
};
