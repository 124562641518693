import * as React from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';

import { ErrorBoundary } from '@/pages/ErrorBoundary';
import { routerUrls } from '@/shared/config/routes';

import { App } from '../App';
import { withAuthCheck } from '../hocs/withAuthCheck';

const MainPage = withAuthCheck({ Component: React.lazy(() => import('@/pages/Main')) });
const ClientsPage = withAuthCheck({ Component: React.lazy(() => import('@/pages/Clients')) });
const LoginPage = withAuthCheck({ Component: React.lazy(() => import('@/pages/Login')), needAuth: false });
const ChangePasswordPage = withAuthCheck({
  Component: React.lazy(() => import('@/pages/ChangePassword')),
  needAuth: false,
});
const AgentsPage = withAuthCheck({ Component: React.lazy(() => import('@/pages/Agents')) });
const TradesPage = withAuthCheck({ Component: React.lazy(() => import('@/pages/Trades')) });
const TradeWorkflowPage = withAuthCheck({
  Component: React.lazy(() => import('@/pages/TradeWorkflow')),
});
const DocumentsPage = withAuthCheck({ Component: React.lazy(() => import('@/pages/Documents')) });

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: routerUrls.main.mask,
        element: <MainPage />,
      },
      {
        path: routerUrls.login.mask,
        element: <LoginPage />,
      },
      {
        path: routerUrls.changePassword.mask,
        element: <ChangePasswordPage />,
      },
      {
        path: routerUrls.clients.mask,
        element: <ClientsPage />,
      },
      {
        path: routerUrls.agents.mask,
        element: <AgentsPage />,
      },
      {
        path: routerUrls.trades.mask,
        element: <TradesPage />,
      },
      {
        path: routerUrls.tradeWorkflow.mask,
        element: <TradeWorkflowPage />,
      },
      {
        path: routerUrls.documents.mask,
        element: <DocumentsPage />,
      },
      {
        path: '*',
        element: <Navigate to={'/'} replace />,
      },
    ],
  },
]);

export default router;
