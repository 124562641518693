import * as React from 'react';

import { IconProps } from '../types';

const FileTextFilledIcon: React.FC<IconProps> = ({ size = 16, ...props }) => {
  return (
    <svg {...props} width={size} height={size} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8531 4.51094C13.9469 4.60469 14 4.73125 14 4.86406V14.5C14 14.7766 13.7766 15 13.5 15H3.5C3.22344 15 3 14.7766 3 14.5V1.5C3 1.22344 3.22344 1 3.5 1H10.1359C10.2688 1 10.3969 1.05313 10.4906 1.14688L13.8531 4.51094ZM12.8469 5.09375L9.90625 2.15313V5.09375H12.8469ZM5.5 7.53125C5.46685 7.53125 5.43505 7.54442 5.41161 7.56786C5.38817 7.5913 5.375 7.6231 5.375 7.65625V8.40625C5.375 8.4394 5.38817 8.4712 5.41161 8.49464C5.43505 8.51808 5.46685 8.53125 5.5 8.53125H11.5C11.5332 8.53125 11.5649 8.51808 11.5884 8.49464C11.6118 8.4712 11.625 8.4394 11.625 8.40625V7.65625C11.625 7.6231 11.6118 7.5913 11.5884 7.56786C11.5649 7.54442 11.5332 7.53125 11.5 7.53125H5.5ZM5.5 9.65625C5.46685 9.65625 5.43505 9.66942 5.41161 9.69286C5.38817 9.7163 5.375 9.7481 5.375 9.78125V10.5312C5.375 10.5644 5.38817 10.5962 5.41161 10.6196C5.43505 10.6431 5.46685 10.6562 5.5 10.6562H8.375C8.40815 10.6562 8.43995 10.6431 8.46339 10.6196C8.48683 10.5962 8.5 10.5644 8.5 10.5312V9.78125C8.5 9.7481 8.48683 9.7163 8.46339 9.69286C8.43995 9.66942 8.40815 9.65625 8.375 9.65625H5.5Z"
        fill="currentColor"
        fillOpacity="0.88"
      />
    </svg>
  );
};

export default React.memo(FileTextFilledIcon);
