import { UserType } from '@/entities/user';
import { routerUrls } from '@/shared/config/routes';
import { getRootPathname } from '@/shared/utils';

export const roleRouteMap: Record<UserType, string[]> = {
  [UserType.agent]: [
    routerUrls.login.mask,
    routerUrls.changePassword.mask,
    routerUrls.clients.mask,
    routerUrls.agents.mask,
    routerUrls.trades.mask,
    routerUrls.documents.mask,
  ].map(getRootPathname),
  [UserType.supplier]: [
    routerUrls.login.mask,
    routerUrls.changePassword.mask,
    routerUrls.trades.mask,
    routerUrls.documents.mask,
  ].map(getRootPathname),
  [UserType.customer]: [
    routerUrls.login.mask,
    routerUrls.changePassword.mask,
    routerUrls.trades.mask,
    routerUrls.documents.mask,
  ].map(getRootPathname),
  [UserType.manager]: [
    routerUrls.login.mask,
    routerUrls.changePassword.mask,
    routerUrls.clients.mask,
    routerUrls.agents.mask,
    routerUrls.trades.mask,
    routerUrls.documents.mask,
  ].map(getRootPathname),
};

export const defaultRoleRoute: Record<UserType, string> = {
  [UserType.agent]: routerUrls.clients.create(),
  [UserType.supplier]: routerUrls.trades.create(),
  [UserType.customer]: routerUrls.trades.create(),
  [UserType.manager]: routerUrls.clients.create(),
};
