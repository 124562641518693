import { LSKey } from '@/shared/config/LSkeys';
import { Nullable } from '@/shared/types/values';

import { ObjectStorage } from '../ObjectStorage';

export class SessionStorageModel implements Storage {
  private storage: Storage;

  constructor() {
    if (window.sessionStorage) {
      this.storage = window.sessionStorage;
    } else {
      this.storage = new ObjectStorage();
    }
  }

  get length(): number {
    return this.storage.length;
  }

  getItem(key: LSKey): Nullable<string> {
    return this.storage.getItem(key);
  }

  clear(): void {
    this.storage.clear();
  }

  key(keyIndex: number): Nullable<string> {
    return this.storage.key(keyIndex);
  }

  removeItem(key: LSKey): void {
    this.storage.removeItem(key);
  }

  setItem(key: LSKey, value: string): void {
    this.storage.setItem(key, value);
  }
}
