import * as React from 'react';

import { IconProps } from '../types';

const ContactsFilledIcon: React.FC<IconProps> = ({ size = 16, ...props }) => {
  return (
    <svg {...props} width={size} height={size} fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5 3.5H12v-.875a.125.125 0 0 0-.125-.125H11a.125.125 0 0 0-.125.125V3.5H8.562v-.875a.125.125 0 0 0-.124-.125h-.876a.125.125 0 0 0-.125.125V3.5H5.126v-.875A.125.125 0 0 0 5 2.5h-.875A.125.125 0 0 0 4 2.625V3.5H1.5A.5.5 0 0 0 1 4v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5V4a.5.5 0 0 0-.5-.5Zm-4.172 8h-.686a.125.125 0 0 1-.123-.117A1.528 1.528 0 0 0 8 9.969c-.8 0-1.46.625-1.519 1.414a.125.125 0 0 1-.123.117h-.686a.125.125 0 0 1-.125-.131c.044-.833.5-1.558 1.166-1.97a1.747 1.747 0 0 1-.455-1.18c0-.967.78-1.75 1.74-1.75.961 0 1.741.783 1.741 1.75 0 .454-.172.867-.455 1.18a2.47 2.47 0 0 1 1.166 1.97.12.12 0 0 1-.122.131ZM8 7.406a.81.81 0 0 0-.808.813A.81.81 0 0 0 8 9.03a.81.81 0 0 0 .808-.812A.81.81 0 0 0 8 7.406Z"
        fill="currentColor"
        fillOpacity="0.88"
      />
    </svg>
  );
};

export default React.memo(ContactsFilledIcon);
