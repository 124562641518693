import * as React from 'react';

import { IconProps } from '../types';

const LeaveFilledIcon: React.FC<IconProps> = ({ size = 16, ...props }) => {
  return (
    <svg {...props} width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5 1.75H2.5C2.30109 1.75 2.11032 1.80531 1.96967 1.90377C1.82902 2.00223 1.75 2.13576 1.75 2.275V7L5.5 4.9V6.475H10V7.525H5.5V9.1L1.75 7V11.725C1.75 11.8642 1.82902 11.9978 1.96967 12.0962C2.11032 12.1947 2.30109 12.25 2.5 12.25H11.5C11.6989 12.25 11.8897 12.1947 12.0303 12.0962C12.171 11.9978 12.25 11.8642 12.25 11.725V2.275C12.25 2.13576 12.171 2.00223 12.0303 1.90377C11.8897 1.80531 11.6989 1.75 11.5 1.75Z"
        fill="currentColor"
        fillOpacity="0.88"
      />
    </svg>
  );
};

export default React.memo(LeaveFilledIcon);
