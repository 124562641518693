import { Options } from '@/shared/types/values';

import { UserType } from './types';

export const mapUserTypeToLabel: Record<UserType, string> = {
  [UserType.agent]: 'Агент',
  [UserType.customer]: 'Покупатель',
  [UserType.manager]: 'Менеджер',
  [UserType.supplier]: 'Поставщик',
};

export const roleList: UserType[] = [UserType.manager, UserType.supplier, UserType.customer, UserType.agent];

export const userFilterOptions: Options<UserType> = [
  {
    label: mapUserTypeToLabel[UserType.customer],
    value: UserType.customer,
  },
  {
    label: mapUserTypeToLabel[UserType.supplier],
    value: UserType.supplier,
  },
];
