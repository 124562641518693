export const routerUrls = {
  main: {
    mask: '/',
    create: () => '/',
  },
  login: {
    mask: '/login',
    create: () => '/login',
  },
  changePassword: {
    mask: '/change_password',
  },
  clients: {
    mask: '/clients',
    create: () => '/clients',
  },
  agents: {
    mask: '/agents',
    create: () => '/agents',
  },
  trades: {
    mask: '/trades',
    create: () => '/trades',
  },
  tradeWorkflow: {
    mask: '/trades/:tradeId',
    create: (id: string) => `/trades/${id}`,
  },
  documents: {
    mask: '/documents',
    create: () => '/documents',
  },
};
