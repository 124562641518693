import { ApiStore, HttpAuthorizationScheme } from '@kts-front/call-api';
import { message } from 'antd';
import cn from 'classnames';
import { nanoid } from 'nanoid';

import { mapErrorStatusToMessage } from '@/pages/Login/model/LoginStore/config';
import { LSKey } from '@/shared/config/LSkeys';
import { ownLocalStorage, ownSessionStorage } from '@/shared/model/storage';
import s from '@/shared/styles/notification.module.scss';

import { ApiErrorCode, ApiErrorData } from '../types/meta';
import { Nullable } from '../types/values';

import { apiPrefix } from './urls';

let key: Nullable<string> = null;

export const apiStore = new ApiStore({
  baseUrl: apiPrefix,
  getAuthorizationCallback: () => {
    const token = ownSessionStorage.getItem(LSKey.accessToken) || ownLocalStorage.getItem(LSKey.accessToken);

    if (token === null) {
      return null;
    }

    return {
      scheme: HttpAuthorizationScheme.Bearer,
      data: token,
    };
  },
  transformResponse: (stringifyResponse) => {
    const response = JSON.parse(stringifyResponse) as Nullable<ApiErrorData>;

    // В случае ошибки (протухший токен) показываем сообщение.
    if (response?.status === 'error' && response.code === ApiErrorCode.tokenNotValid && key === null) {
      key = nanoid();
      message.open({
        key,
        className: cn(s.message, s.message_error),
        type: 'error',
        content: mapErrorStatusToMessage[response.code],
        onClose: () => {
          key = null;
        },
      });
    }

    return response;
  },
});
