import { extend, locale } from 'dayjs';
import 'dayjs/locale/ru';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';

extend(isToday);
extend(isYesterday);
extend(isSameOrAfter);

locale('ru');
