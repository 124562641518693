import { Nullable } from '@/shared/types/values';

export class ObjectStorage implements Storage {
  private storage: Record<string, string> = {};

  get length(): number {
    return Object.keys(this.storage).length;
  }

  getItem(key: string): Nullable<string> {
    return this.storage[key];
  }

  setItem(key: string, value: string): void {
    this.storage[key] = String(value);
  }

  removeItem(key: string): void {
    delete this.storage[key];
  }

  clear(): void {
    this.storage = {};
  }

  key(keyIndex: number) {
    return Object.keys(this.storage)[keyIndex] || null;
  }
}
