import qs from 'query-string';

import { apiPrefix } from '../api';

export const getFullApiUrl = (link: string, params?: Record<string, unknown>) => {
  const searchParams = params && qs.stringify(params, { skipNull: true, skipEmptyString: true, arrayFormat: 'none' });
  const urlSearchParams = searchParams ? `?${searchParams}` : '';

  return location.origin + apiPrefix + link + urlSearchParams;
};
