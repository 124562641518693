import { Locale } from 'antd/es/locale';
import ru from 'antd/es/locale/ru_RU';

export const localeConfig: Locale = {
  ...ru,
  Table: {
    ...ru.Table,
    filterConfirm: 'Применить',
    filterReset: 'Очистить',
    filterSearchPlaceholder: 'Поиск',
  },
  Modal: {
    cancelText: 'Отменить',
    justOkText: 'Применить',
    okText: 'Создать',
  },
};
