import { MenuProps } from 'antd';
import * as React from 'react';

import { routerUrls } from '@/shared/config/routes';
import { ContactsFilledIcon, FileTextFilledIcon, LeaveFilledIcon, TeamFilledIcon } from '@/shared/ui/icons';

const COLOR_ICON_SELECTED = '#ffffff';
const COLOR_ICON_DEFAULT = '#000000';

const getIconColor = (selectedKey: string, key: string) =>
  selectedKey === key ? COLOR_ICON_SELECTED : COLOR_ICON_DEFAULT;

export const menuUrlsMap: Record<string, { key: string; url: string; label: string }> = {
  clients: {
    key: 'clients',
    url: routerUrls.clients.create(),
    label: 'Клиенты',
  },
  agents: {
    key: 'agents',
    url: routerUrls.agents.create(),
    label: 'Агенты',
  },
  trades: {
    key: 'trades',
    url: routerUrls.trades.create(),
    label: 'Сделки',
  },
  documents: {
    key: 'documents',
    url: routerUrls.documents.create(),
    label: 'Документы',
  },
  profile: {
    key: 'profile',
    url: routerUrls.clients.create(),
    label: 'Профиль',
  },
  logout: {
    key: 'logout',
    url: routerUrls.login.create(),
    label: 'Выйти',
  },
};

export const getMenuItems: (
  selectedKey: string,
  handleMenuItemClick: (params: { key: string }) => void,
) => MenuProps['items'] = (selectedKey, handleMenuItemClick) => [
  {
    key: menuUrlsMap.clients.key,
    label: menuUrlsMap.clients.label,
    icon: <TeamFilledIcon color={getIconColor(selectedKey, menuUrlsMap.clients.key)} />,
    onClick: () => handleMenuItemClick({ key: menuUrlsMap.clients.key }),
  },
  {
    key: menuUrlsMap.agents.key,
    label: menuUrlsMap.agents.label,
    icon: <ContactsFilledIcon color={getIconColor(selectedKey, menuUrlsMap.agents.key)} />,
    onClick: () => handleMenuItemClick({ key: menuUrlsMap.agents.key }),
  },
  {
    key: menuUrlsMap.trades.key,
    label: menuUrlsMap.trades.label,
    icon: <FileTextFilledIcon color={getIconColor(selectedKey, menuUrlsMap.trades.key)} />,
    onClick: () => handleMenuItemClick({ key: menuUrlsMap.trades.key }),
  },
  {
    key: menuUrlsMap.documents.key,
    label: menuUrlsMap.documents.label,
    icon: <FileTextFilledIcon color={getIconColor(selectedKey, menuUrlsMap.documents.key)} />,
    onClick: () => handleMenuItemClick({ key: menuUrlsMap.documents.key }),
  },
  {
    type: 'group',
    children: [
      // TODO: implement profile
      // {
      //   key: menuUrlsMap.profile.key,
      //   label: menuUrlsMap.profile.label,
      //   icon: <ProfileIcon color={getIconColor(selectedKey, menuUrlsMap.profile.key)} size={14} />,
      //   onClick: () => {},
      // },
      {
        key: menuUrlsMap.logout.key,
        label: menuUrlsMap.logout.label,
        icon: <LeaveFilledIcon color={COLOR_ICON_DEFAULT} size={14} />,
        onClick: () => handleMenuItemClick({ key: menuUrlsMap.logout.key }),
      },
    ],
  },
];
