import { MenuOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import s from './SideMenuButton.module.scss';

type SideMenuButtonProps = {
  onClick?: () => void;
  className?: string;
};

const SideMenuButton = ({ onClick, className }: SideMenuButtonProps): React.ReactElement<SideMenuButtonProps> => {
  return (
    <Button className={cn(s['side-menu-button'], className)} type="text" onClick={onClick}>
      <MenuOutlined className={s['side-menu-button__icon']} />
    </Button>
  );
};

export default React.memo(SideMenuButton);
