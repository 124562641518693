import * as React from 'react';

import s from './ErrorBoundary.module.scss';

// TODO: Implement ErrorBoundary component
const ErrorBoundary = (): React.ReactElement => {
  return <div className={s['error-boundary']}>Error boundary</div>;
};

export default ErrorBoundary;
