import { Button, Flex, Modal, ModalProps } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import s from './CustomModal.module.scss';

type CustomModalProps = Omit<ModalProps, 'centered' | 'classNames' | 'footer' | 'okType'> & {
  danger?: boolean;
};

const CustomModal: React.FC<CustomModalProps> = ({
  className,
  danger,
  okText,
  cancelText = 'Отменить',
  cancelButtonProps,
  okButtonProps,
  onCancel,
  onOk,
  ...props
}) => {
  const handleCancel = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!cancelButtonProps?.disabled && !cancelButtonProps?.loading && onCancel) {
        onCancel(event);
      }
    },
    [onCancel, cancelButtonProps?.disabled, cancelButtonProps?.loading],
  );

  return (
    <Modal
      rootClassName={cn(s['custom-modal'], className)}
      {...props}
      onCancel={handleCancel}
      centered
      footer={
        <Flex className="ant-modal-footer-content" gap={16}>
          <Button size="large" onClick={handleCancel} {...cancelButtonProps}>
            {cancelText}
          </Button>
          <Button danger={danger} type="primary" size="large" onClick={onOk} {...okButtonProps}>
            {okText}
          </Button>
        </Flex>
      }
    />
  );
};

export default CustomModal;
