import { ISupplier } from '../../types/client';
import { SupplierServer, UserType } from '../../types/server';
import { UserModel } from '../UserModel';

export class SupplierModel extends UserModel<UserType.supplier> implements ISupplier {
  static fromJson(data: SupplierServer): SupplierModel {
    return new SupplierModel({
      id: data.id,
      name: data.name,
      email: data.email,
      phone: data.phone,
      address: data.address,
      accepted: data.accepted,
      type: UserType.supplier,
    });
  }
}
