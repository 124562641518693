import { action, computed, makeObservable, observable, reaction } from 'mobx';
import qs from 'query-string';

import { LocalStore } from './LocalStore';
import { RouterStore } from './RouterStore';

export type QueryParamsModelParams<P> = {
  params: P;
  routerStore: RouterStore;
};

export class QueryParamsModel<P extends Record<string, any>> extends LocalStore {
  readonly routerStore: RouterStore;
  private _params: P;

  constructor({ params, routerStore }: QueryParamsModelParams<P>) {
    super();

    makeObservable<this, '_params'>(this, {
      _params: observable.ref,
      params: computed,
      setParam: action,
    });

    this._params = params;
    this.routerStore = routerStore;

    this.addReactions([
      reaction(
        () => this._params,
        () =>
          this.routerStore.navigate(
            { search: qs.stringify(this._params, { skipNull: true, skipEmptyString: true }) },
            { replace: true },
          ),
      ),
    ]);
  }

  get params(): P {
    return this._params;
  }

  setParam = <K extends keyof P>(param: K, value: P[K]): void => {
    this._params = {
      ...this.params,
      [param]: value,
    };
  };

  setParams = (params: Partial<P>): void => {
    this._params = {
      ...this.params,
      ...params,
    };
  };

  resetParams = (): void => {
    this._params = {} as P;
  };
}
