import { Button, Checkbox, Divider, Flex } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';
import * as React from 'react';

import { ID } from '@/shared/types/meta';
import { Nullable, Options } from '@/shared/types/values';

import s from './CustomFilterDropdown.module.scss';

type CustomFilterDropdownProps<Value> = FilterDropdownProps & {
  currentValue: Nullable<Value>;
  onChange: (value: Nullable<Value>) => void;
  options: Options<Value>;
};

const CustomFilterDropdown = <Value extends ID | boolean>({
  currentValue,
  options,
  onChange,
  close,
}: CustomFilterDropdownProps<Value>) => {
  const handleReset = React.useCallback(() => {
    onChange(null);
    close();
  }, [onChange, close]);

  return (
    <div className={s.filter}>
      <Flex className={s.filter_list} vertical>
        {options.map(({ value, label }) => {
          const handleChange = React.useCallback(() => {
            onChange(value);
            close();
          }, [onChange, close]);

          return (
            <Checkbox
              className={s.filter_list_option}
              key={String(value)}
              checked={value === currentValue}
              onChange={handleChange}
            >
              {label}
            </Checkbox>
          );
        })}
      </Flex>
      <Divider className={s.filter_divider} />
      <Button className={s.filter_button} size="small" onClick={handleReset}>
        Сбросить
      </Button>
    </div>
  );
};

export default CustomFilterDropdown;
