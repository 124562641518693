import { Button, Empty } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import s from './EmptySearchStub.module.scss';

type EmptySearchStubProps = {
  onResetClick: () => void;
  className?: string;
};

const EmptySearchStub = ({
  onResetClick,
  className,
}: EmptySearchStubProps): React.ReactElement<EmptySearchStubProps> => {
  return (
    <Empty className={cn(s['empty-search-stub'], className)} description="Ничего не найдено">
      <Button type="primary" onClick={onResetClick}>
        Сбросить поиск
      </Button>
    </Empty>
  );
};

export default EmptySearchStub;
