import { StepProps } from 'antd';
import * as React from 'react';

import { useWidth } from '@/shared/hooks/useWidth';

import StageContentDesktop from './StageContentDesktop';
import StageContentTablet from './StageContentTablet';
import StageSteps from './StageSteps';

type StageContentProps = {
  className?: string;
  currentStep: number;
  items: StepProps[];
  children?: React.ReactNode;
};

const StageContent: React.FC<StageContentProps> = ({ className, currentStep, items, children }) => {
  const { isDesktop } = useWidth();
  const requiredSteps = React.useMemo(
    () => <StageSteps currentStep={currentStep} items={items} />,
    [currentStep, items],
  );

  return isDesktop ? (
    <StageContentDesktop className={className} requiredSteps={requiredSteps} optionalSteps={children} />
  ) : (
    <StageContentTablet className={className} requiredSteps={requiredSteps} optionalSteps={children} />
  );
};

export default StageContent;
