import { SortDirection, SortDirectionAntd } from '../types/meta';

export const normalizeSortDirection = (sortDirection: SortDirectionAntd): SortDirection => {
  switch (sortDirection) {
    case 'ascend':
      return SortDirection.asc;

    case 'descend':
      return SortDirection.desc;

    default:
      return SortDirection.none;
  }
};

export const denormalizeSortDirection = (sortDirection: SortDirection): SortDirectionAntd => {
  switch (sortDirection) {
    case SortDirection.asc:
      return 'ascend';

    case SortDirection.desc:
      return 'descend';

    default:
      return null;
  }
};
