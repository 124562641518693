import { ConfigProvider } from 'antd';
import * as React from 'react';
import { RouterProvider } from 'react-router-dom';

import { localeConfig } from '@/shared/config/localeConfig';
import { themeConfig } from '@/shared/config/themeConfig';

import { router } from './routes';

const Root = (): React.ReactElement => {
  return (
    <ConfigProvider theme={themeConfig} locale={localeConfig}>
      <RouterProvider router={router} />
    </ConfigProvider>
  );
};

export default Root;
