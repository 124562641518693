import { IManager } from '../../types/client';
import { ManagerServer, UserType } from '../../types/server';
import { UserModel } from '../UserModel';

export class ManagerModel extends UserModel<UserType.manager> implements IManager {
  static fromJson(data: ManagerServer): ManagerModel {
    return new ManagerModel({
      id: data.id,
      name: data.name,
      email: data.email,
      phone: data.phone,
      address: data.address,
      accepted: data.accepted,
      type: UserType.manager,
    });
  }
}
