import { IBaseUser, UserType } from '../../types';

export class UserModel<T extends UserType> implements IBaseUser<T> {
  readonly id: number;
  readonly name: string;
  readonly email: string;
  readonly phone: string;
  readonly address: string;
  readonly accepted: boolean;
  readonly type: T;

  constructor(data: IBaseUser<T>) {
    this.id = data.id;
    this.name = data.name;
    this.email = data.email;
    this.phone = data.phone;
    this.address = data.address;
    this.accepted = data.accepted;
    this.type = data.type;
  }
}
