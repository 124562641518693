import { Flex, Typography } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import s from './StageContent.module.scss';

type StageContentDesktopProps = {
  className?: string;
  requiredSteps: React.ReactNode;
  optionalSteps?: React.ReactNode;
};

const StageContentDesktop: React.FC<StageContentDesktopProps> = ({ className, requiredSteps, optionalSteps }) => {
  return (
    <>
      <Typography.Title level={5}>Текущий шаг</Typography.Title>
      <Flex className={cn(s.stage, className)} gap={20}>
        {requiredSteps}
        {optionalSteps && (
          <>
            <div className={s.stage_border} />
            <Flex className={s.stage_optional} vertical gap={12}>
              {optionalSteps}
            </Flex>
          </>
        )}
      </Flex>
    </>
  );
};

export default React.memo(StageContentDesktop);
