import { computed, makeObservable } from 'mobx';

import { Validator } from '../types/validator';

import { ValueModel } from './ValueModel';

export class ToggleModel extends ValueModel<boolean> {
  constructor(value: boolean = false, validators: Validator<boolean>[] = []) {
    super(value, validators);

    makeObservable(this, {
      isOpen: computed,
    });
  }
  get isOpen() {
    return this.value;
  }

  toggle = () => {
    this.change(!this.value);
  };

  close = () => {
    this.change(false);
  };

  open = () => {
    this.change(true);
  };
}
