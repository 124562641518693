import { ICustomer } from '../../types/client';
import { CustomerServer, UserType } from '../../types/server';
import { UserModel } from '../UserModel';

export class CustomerModel extends UserModel<UserType.customer> implements ICustomer {
  static fromJson(data: CustomerServer): CustomerModel {
    return new CustomerModel({
      id: data.id,
      name: data.name,
      email: data.email,
      phone: data.phone,
      address: data.address,
      accepted: data.accepted,
      type: UserType.customer,
    });
  }
}
