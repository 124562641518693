export enum LoadingStage {
  notStarted = 'notStarted',
  loading = 'loading',
  success = 'success',
  error = 'error',
}

export enum SortDirection {
  asc = 'asc',
  desc = 'desc',
  none = 'none',
}

export type SortDirectionAntd = 'ascend' | 'descend' | undefined | null;

export type ID = number | string;

export enum ApiErrorCode {
  authenticationFailed = 'authentication_failed',
  tokenNotValid = 'token_not_valid',
}

export type ApiErrorData<D extends Record<string, unknown> = Record<string, unknown>> = {
  status: string;
  code: ApiErrorCode;
  message: string;
  data?: D;
};

export type ApiFieldsErrorData<Fields extends string> = ApiErrorData & {
  field_problems?: Partial<Record<Fields, string[]>>;
};

export type ApiListResponse<R> = {
  total: number;
  results: R[];
};
