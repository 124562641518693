import * as React from 'react';

type ProgressIconProps = {
  className?: string;
};

const ProgressIcon: React.FC<ProgressIconProps> = (props) => {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill="#E6F4FF" />
      <path
        d="M13.9059 6.85547H13.2233C13.1276 6.85547 13.0368 6.89941 12.9782 6.97461L8.95182 12.0752L7.02115 9.62891C6.99195 9.59182 6.95472 9.56184 6.91227 9.54121C6.86981 9.52057 6.82324 9.50982 6.77604 9.50977H6.09342C6.02799 9.50977 5.99186 9.58496 6.0319 9.63574L8.7067 13.0244C8.8317 13.1826 9.07194 13.1826 9.19791 13.0244L13.9674 6.98047C14.0075 6.93066 13.9713 6.85547 13.9059 6.85547Z"
        fill="#1677FF"
      />
    </svg>
  );
};

export default React.memo(ProgressIcon);
