import { Result as ResultAntd } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import s from './CustomResult.module.scss';

type CustomResultProps = React.PropsWithChildren<{
  className?: string;
  title: string;
  subTitle?: string;
  icon?: React.ReactNode;
  titleSize?: 'large' | 'small';
}>;

const CustomResult: React.FC<CustomResultProps> = ({
  className,
  children,
  title,
  subTitle,
  icon,
  titleSize = 'small',
}) => {
  return (
    <ResultAntd
      className={cn(s.result, s[`result_title-${titleSize}`], className)}
      title={title}
      subTitle={subTitle}
      icon={icon}
    >
      {children}
    </ResultAntd>
  );
};

export default CustomResult;
