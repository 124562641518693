import { ApiListResponse } from '@/shared/types/meta';

/**
 * @field agent - Агент
 * @field customer - Покупатель
 * @field manager - Менеджер
 * @field supplier - Поставщик
 */
export enum UserType {
  agent = 'agent',
  customer = 'customer',
  manager = 'manager',
  supplier = 'supplier',
}

export type UserBaseServer<T extends UserType> = {
  id: number;
  email: string;
  phone: string;
  name: string;
  address: string;
  // Статус принятия заявки
  accepted: boolean;
  type: T;
};

export type AgentServer = UserBaseServer<UserType.agent>;

export type ManagerServer = UserBaseServer<UserType.manager>;

export type CustomerServer = UserBaseServer<UserType.customer>;

export type SupplierServer = UserBaseServer<UserType.supplier>;

export type ClientServer = CustomerServer | SupplierServer;

export type UserServer = AgentServer | ClientServer | ManagerServer;

export type ClientsListResponse = ApiListResponse<CustomerServer | SupplierServer>;
