import { AgentServer, UserType } from '../../types/server';
import { UserModel } from '../UserModel';

export class AgentModel extends UserModel<UserType.agent> {
  static fromJson(raw: AgentServer): AgentModel {
    return new AgentModel({
      id: raw.id,
      name: raw.name,
      email: raw.email,
      phone: raw.phone,
      address: raw.address,
      accepted: raw.accepted,
      type: UserType.agent,
    });
  }
}
