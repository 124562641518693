import { message } from 'antd';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { RootStore } from './RootStore';

const RootStoreContext = React.createContext<RootStore | null>(null);

export const RootStoreContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage({
    maxCount: 5,
  });

  const rootStore = React.useRef(new RootStore({ messageApi, navigate }));

  React.useEffect(() => {
    rootStore.current.userStore.getCurrentUser();
  }, []);

  return (
    <RootStoreContext.Provider value={rootStore.current}>
      {children}
      {contextHolder}
    </RootStoreContext.Provider>
  );
};

export const useRootStore = () => {
  const context = React.useContext(RootStoreContext);

  if (!context) {
    throw new Error('RootStoreContext was not provided');
  }

  return context;
};
