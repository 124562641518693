import { ILocalStore } from '@kts-front/types';
import { IReactionDisposer } from 'mobx';

export abstract class LocalStore implements ILocalStore {
  private reactionDisposers: IReactionDisposer[] = [];

  addReactions(reactions: IReactionDisposer[]): void {
    for (const r of reactions) {
      this.reactionDisposers.push(r);
    }
  }

  destroyReactions(): void {
    this.reactionDisposers.forEach((disposer) => disposer());
  }

  destroy(): void {
    this.destroyReactions();
  }
}
