import { Alert, AlertProps } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import s from './CustomAlert.module.scss';

const CustomAlert: React.FC<AlertProps> = ({ className, ...props }) => {
  return (
    <div className={cn(s.alert, className)}>
      <Alert {...props} banner />
    </div>
  );
};

export default React.memo(CustomAlert);
