import { Button } from 'antd';
import * as React from 'react';

import { CustomResult } from '../CustomResult';
import { OfflineIcon } from '../icons';

type OfflineStatusProps = {
  className?: string;
};

const OfflineStatus: React.FC<OfflineStatusProps> = ({ className }) => {
  return (
    <CustomResult
      className={className}
      icon={<OfflineIcon />}
      title="Проверьте подключение к интернету"
      subTitle="Проверьте подключение к Wi-Fi или мобильной сети и повторите попытку"
      titleSize="large"
    >
      <Button type="primary" onClick={() => window.location.reload()}>
        Повторить попытку
      </Button>
    </CustomResult>
  );
};

export default React.memo(OfflineStatus);
