import { MessageInstance } from 'antd/es/message/interface';
import cn from 'classnames';
import { action, makeObservable } from 'mobx';
import { nanoid } from 'nanoid';

import s from '@/shared/styles/notification.module.scss';

import { IMessage } from '../types';

import { INotificationsStore, MessageKey } from './types';

export class NotificationsStore implements INotificationsStore {
  private readonly _api: MessageInstance;

  constructor(messageApi: MessageInstance) {
    this._api = messageApi;

    makeObservable(this, {
      addNotification: action.bound,
    });
  }

  addNotification({ type, message, ...args }: IMessage): MessageKey {
    const key = nanoid();

    this._api.open({
      className: cn(s.message, s[`message_${type}`]), // TODO: Возможно надо будет поменять стили
      key,
      content: message,
      type: type,
      ...args,
    });

    return key;
  }

  destroy(key: MessageKey): void {
    this._api.destroy(key);
  }
}
