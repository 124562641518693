import { ThemeConfig } from 'antd';

export const themeConfig: ThemeConfig = {
  token: {
    fontFamily: 'SF Pro Text, sans-serif',
  },
  components: {
    Alert: {
      defaultPadding: '20px 16px',
      withDescriptionPadding: '20px 16px',
    },
    Menu: {
      itemSelectedBg: '#1677ff',
      itemBg: 'transparent',
      itemHoverBg: '#1677ff20',
      itemSelectedColor: '#ffffff',
      iconMarginInlineEnd: 8,
    },
    Typography: {
      titleMarginBottom: 0,
      titleMarginTop: 0,
    },
    Modal: {
      titleFontSize: 24,
    },
    Steps: {
      dotSize: 10,
      dotCurrentSize: 10,
      titleLineHeight: 24,
      iconSize: 24,
    },
    Tabs: {
      horizontalItemGutter: 10,
    },
    Table: {
      rowHoverBg: '#E6F4FF',
    },
  },
};
