import { GlobalOutlined } from '@ant-design/icons';
import { Flex, Select } from 'antd';
import * as React from 'react';

type LanguageSelectorProps = {
  className?: string;
  hidden?: boolean; // TODO: Удалить после реализации локализации
};

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ className, hidden = true }) => {
  // TODO: Удалить после реализации локализации,
  // необходимо для сохранения высоты хедера!
  if (hidden) {
    return <div style={{ height: '32px' }} />;
  }

  return (
    <Select
      className={className}
      defaultValue={'ru'}
      labelRender={({ label }) => (
        <Flex align="center" gap={8}>
          <GlobalOutlined />
          {label}
        </Flex>
      )}
      options={[
        { value: 'ru', label: 'Русский' },
        { value: 'en', label: 'English' },
      ]}
    />
  );
};

export default LanguageSelector;
